<template>
	<ol class="tnCarouselPagination">
		<li
			v-for="slide in slideRange"
			:key="slide"
			class="tnCarouselPaginationItem"
		>
			<button
				type="button"
				:class="{
					tnCarouselPaginationButton: true,
					active: isActive(slide),
					dark: dark,
				}"
				:aria-label="defaultConfig.i18n.ariaNavigateToSlide + ' ' + slide + 1"
				@click="nav.slideTo(slide)"
			/>
		</li>
	</ol>
</template>

<script lang="ts" setup>
import tnCarouselConfig from "@/components/platform/common/TelenorComponents/TnCarouselNuxt/tnCarouselConfig";
import { injectCarousel } from "vue3-carousel";

defineProps({
	dark: Boolean,
});
const defaultConfig = ref({ ...tnCarouselConfig.defaultConfig });
const carouselProvider = inject(injectCarousel);
const { maxSlide, minSlide, currentSlide, nav } = toRefs(carouselProvider);

const isActive = (slide) => {
	return (
		mapNumberToRange({
			val: currentSlide.value,
			max: maxSlide.value,
			min: 0,
		}) === slide
	);
};

const mapNumberToRange = ({ val, max, min = 0 }: { val: number; max: number; min: number }) => {
	if (val > max) {
		return mapNumberToRange({ val: val - (max + 1), max, min });
	}
	if (val < min) {
		return mapNumberToRange({ val: val + (max + 1), max, min });
	}
	return val;
};

// Generate a range of slides from minSlide to maxSlide
const slideRange = computed(() => {
	const range = [];
	for (let slide = minSlide.value; slide <= maxSlide.value; slide++) {
		range.push(slide);
	}
	return range;
});
</script>

<style scoped lang="scss">
@use "@/assets/scss/variables" as variables;

.tnCarouselPagination {
	display: flex;
	justify-content: center;
	list-style: none;
	margin: $spacing-s 0 0 0;
	gap: 7px;
}

.tnCarouselPaginationButton {
	background-color: variables.$color-cta-disabled;
	border: none;
	cursor: pointer;
	padding: 8px;
	border-radius: 50%;
	border: 4px solid transparent;
	background-clip: padding-box; /* Ensures the background color doesn't go into the border area */

	&.dark {
		background-color: variables.$color-cta-dark-disabled;
	}

	&.active {
		background-color: variables.$color-cta-active;

		&.dark {
			background-color: variables.$color-cta-dark-active;
		}
	}

	&:hover {
		background-color: variables.$color-cta-hover;

		&.dark {
			background-color: variables.$color-cta-dark-hover;
		}
	}
}
</style>
