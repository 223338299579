<template>
	<Carousel
		:wrapAround="props.infiniteScroll"
		:itemsToShow="props.slidesToShow"
		:modelValue="props.initialSlide - 1"
		:snapAlign="props.snapAlign"
		:mouseDrag="props.mouseDrag"
		:touchDrag="props.touchDrag"
		:autoPlay="props.autoPlay"
		:pauseAutoplayOnHover="props.pauseAutoplayOnHover"
		@slideStart="slideStart"
		@slideEnd="slideEnd"
	>
		<!-- Default slot to be used with TnCarouselSlideNuxt -->
		<slot />
		<template #addons>
			<TnCarouselNavigationNuxt
				:dark="dark"
				:infiniteScroll="infiniteScroll"
				class="addons"
				:class="{ mounted: mounted }"
				v-if="showNavigation && mounted"
			/>
			<TnCarouselPaginationNuxt
				v-if="showPagination"
				class="addons"
				:class="{ mounted: mounted }"
				:dark="darkPagination !== undefined ? darkPagination : dark"
			/>
		</template>
	</Carousel>
</template>
<script lang="ts" setup>
const props = defineProps({
	/**
	 * The amount of slides shown simultaneously
	 */
	slidesToShow: {
		type: Number,
		default: 1,
	},
	/**
	 * Toggles infinite scroll for slides on/off
	 */
	infiniteScroll: {
		type: Boolean,
		default: true,
	},
	/**
	 * If dark, set arrows and dots to white, default: Black
	 */
	dark: {
		type: Boolean,
		default: false,
	},
	/**
	 * Override dark prop for pagination dots
	 */
	darkPagination: {
		type: Boolean,
		required: false,
		default: undefined,
	},
	/**
	 * The first slide to show
	 */
	initialSlide: {
		type: Number,
		default: 1,
	},
	/**
	 * controls the carousel position alignment, can be 'start', 'end', 'center-[odd|even]'
	 */
	snapAlign: {
		type: String,
		default: "center",
	},
	/**
	 * Show pagination dots
	 */
	showPagination: {
		type: Boolean,
		default: true,
	},
	/**
	 * Show navigation buttons
	 */
	showNavigation: {
		type: Boolean,
		default: true,
	},
	/**
	 * Toggle mouse drag on/off
	 */
	mouseDrag: {
		type: Boolean,
		default: true,
	},
	/**
	 * Toggle touch drag on/off
	 */
	touchDrag: {
		type: Boolean,
		default: true,
	},
	/**
	 * autoplay the carousel, auto play time in ms
	 */
	autoPlay: {
		type: Number,
		default: 0,
	},
	/**
	 * pause autoplay on hover
	 */
	pauseAutoplayOnHover: {
		type: Boolean,
		default: true,
	},
});

const emit = defineEmits(["slideStart", "slideEnd"]);

const slideStart = (value: number) => {
	emit("slideStart", value);
};

const slideEnd = (value: number) => {
	emit("slideEnd", value);
};

const mounted = ref(false);

onMounted(() => {
	mounted.value = true;
});
</script>
<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;

.carousel:deep() {
	text-align: unset;
}
.addons {
	opacity: 0;
	transition: opacity 500ms;
	&.mounted {
		opacity: 1;
	}
}
</style>
