<template>
	<!-- This is the component that the parent Carousel is looking for in the DOM -->
	<Slide
		:isClone="props.isClone"
		:style="{ width: width }"
		:class="{
			carousel__slide: true,
			'carousel__slide--clone': isClone,
			'carousel__slide--visible': isVisible,
			'carousel__slide--active': isActive,
			'carousel__slide--prev': isPrev,
			'carousel__slide--next': isNext,
			'carousel__slide--sliding': isSliding,
		}"
		:aria-hidden="!isVisible"
		:index="props.index"
	>
		<!-- Render slot and pass slot props -->
		<slot
			:is-active="isActive"
			:is-clone="isClone"
			:is-prev="isPrev"
			:is-next="isNext"
			:is-sliding="isSliding"
			:is-visible="isVisible"
		/>
	</Slide>
</template>
<script lang="ts" setup>
import { injectCarousel } from "vue3-carousel";

const props = defineProps({
	index: {
		type: Number,
		default: 1,
	},
	isClone: {
		type: Boolean,
		default: false,
	},
});

const width = computed(() => {
	const itemsToShow = Number(config.value.itemsToShow) || 1;
	return `${100 / itemsToShow}%`;
});

// The Carousel provide key is a Symbol('carousel'), thus must be imported
const carouselProvider = inject(injectCarousel);
const { config, currentSlide, isSliding } = toRefs(carouselProvider);
const slidesToScroll = computed(() => config.value?.itemsToScroll || 1);

// Computed properties to determine slide's state
const isActive = computed(() => props.index === currentSlide.value);
const isPrev = computed(() => props.index === currentSlide.value - 1);
const isNext = computed(() => props.index === currentSlide.value + 1);
const isVisible = computed(() => {
	const min = Math.floor(slidesToScroll.value);
	const max = Math.ceil(slidesToScroll.value + Number(config.value.itemsToShow) - 1);
	return props.index >= min && props.index <= max;
});
</script>
