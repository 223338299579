<template>
	<div>
		<!-- Previous Button -->
		<TnButton
			type="button"
			:class="['carousel__prev', $attrs.class]"
			:disabled="!wrapAround && currentSlide <= minSlide && !infiniteScroll"
			:aria-label="i18n.ariaPreviousSlide"
			:iconOnly="isRTL ? 'chevron-right' : 'chevron-left'"
			size="m"
			:dark="dark"
			tertiary
			@click="nav.prev"
		>
		</TnButton>

		<!-- Next Button -->
		<TnButton
			type="button"
			:class="['carousel__next', $attrs.class]"
			:disabled="!wrapAround && currentSlide >= maxSlide && !infiniteScroll"
			:aria-label="i18n.ariaNextSlide"
			:iconOnly="isRTL ? 'chevron-left' : 'chevron-right'"
			size="m"
			:dark="dark"
			tertiary
			@click="nav.next"
		>
		</TnButton>
	</div>
</template>

<script lang="ts" setup>
import tnCarouselConfig from "@/components/platform/common/TelenorComponents/TnCarouselNuxt/tnCarouselConfig";
import { injectCarousel } from "vue3-carousel";

defineProps({
	dark: Boolean,
	infiniteScroll: Boolean,
});

const carouselProvider = inject(injectCarousel);
const { maxSlide, minSlide, currentSlide, nav } = toRefs(carouselProvider);

const { dir, wrapAround, i18n } = tnCarouselConfig.defaultConfig;
const isRTL = dir === "rtl";
</script>
<style lang="scss" scoped>
.carousel__prev,
.carousel__next {
	@include breakpoint(mobile) {
		margin: 0;
		padding: 0 !important;
		width: fit-content !important;
	}
}
</style>
